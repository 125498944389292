import { t } from "i18n:astro";
import ExpandableSection from "../../../components/layout/ExpandableSection/ExpandableSection";
import { ExpandableSectionSize } from "../../../components/layout/ExpandableSection/types";
import { ExpandableId } from "../../../data/expandable";
import { ElementTheme } from "../../../types/theme";
import "./style.scss";

const expandableConfig = {
  size: ExpandableSectionSize.STANDARD,
  theme: ElementTheme.DARK,
};

const ValueDelivery = () => {
  return (
    <>
      <ExpandableSection
        id={ExpandableId.DELIVERY_1}
        {...expandableConfig}
        title={t("home:value-delivery.expandable.acquisition.title")}
      >
        <ul>
          <li>
            <span>{t("home:value-delivery.expandable.acquisition.content.0")}</span>
            <ul>
              <li>
                <span>{t("home:value-delivery.expandable.acquisition.content.1")}</span>
              </li>
            </ul>
          </li>
          <li>
            <span>{t("home:value-delivery.expandable.acquisition.content.2")}</span>
            <ul>
              <li>
                <span>{t("home:value-delivery.expandable.acquisition.content.3")}</span>
              </li>
              <li>
                <span>{t("home:value-delivery.expandable.acquisition.content.4")}</span>
              </li>
              <li>
                <span>{t("home:value-delivery.expandable.acquisition.content.5")}</span>
              </li>
            </ul>
          </li>
        </ul>
      </ExpandableSection>

      <ExpandableSection
        id={ExpandableId.DELIVERY_2}
        {...expandableConfig}
        title={t("home:value-delivery.expandable.sell.title")}
      >
        <ul>
          <li>
            <span>{t("home:value-delivery.expandable.sell.content.0")}</span>
            <ul>
              <li>
                <span>{t("home:value-delivery.expandable.sell.content.1")}</span>
              </li>
            </ul>
          </li>
          <li>
            <span>{t("home:value-delivery.expandable.sell.content.2")}</span>
            <ul>
              <li>
                <span>{t("home:value-delivery.expandable.sell.content.3")}</span>
              </li>
            </ul>
          </li>
        </ul>
      </ExpandableSection>
    </>
  );
};

export default ValueDelivery;
